<template>
    <div>
        <steps :with-buttons="false">
            <step title="Choose File">
                <import-file @parse-file-data="setFilePreviewData"></import-file>
            </step>
            <step title="Map Columns">
                <import-match-columns @set-import-results="setImportResults" :file-data="fileData"></import-match-columns>
            </step>
            <step title="Finalize">
                <import-summary :results="importResults"></import-summary>
            </step>
        </steps>
    </div>
</template>

<script>
    import ModalNavigation from "@/mixins/ModalNavigation";
    import Steps from "@/components/ui/steps/Steps";
    import Step from "@/components/ui/steps/Step";
    import ImportFile from "@/components/cms/websites/floorplans/ImportFile";
    import ImportMatchColumns from "@/components/cms/websites/floorplans/ImportMatchColumns";
    import ImportSummary from "@/components/cms/websites/floorplans/ImportSummary";
    import {mapActions, mapGetters} from "vuex";

    export default {
        mixins: [ ModalNavigation ],
        components: { ImportFile, ImportMatchColumns, ImportSummary, Steps, Step },
        data: () => {
            return {
                website: {},
                fileData: {},
                importResults: []
            }
        },
        computed: {
            ...mapGetters({
                getWebsiteInfo: 'website_info/getWebsiteInfo'
            }),
        },
        methods: {
            ...mapActions({
                notifySuccessBack: 'alerts/notifySuccessBack',
                notifyError: 'alerts/notifyError',
            }),
            setFilePreviewData(payload) {
                this.fileData = payload;
            },
            setImportResults(payload) {
                this.importResults = payload;
            }           
        },
        async mounted() {
            this.website = this.getWebsiteInfo
        },
    }
</script>
