<template>
    <div class="flex flex-col flex-1 h-full pb-8">
        <file-input v-model="uploadedFile" accept=".csv, .xls, .xlsx"></file-input>
        <span v-if="hasError('uploadedFile')" class="form-error w-1/2 mx-auto text-center">{{ showError('uploadedFile') }}</span>
        <div class="form-row modal-footer">
            <div class="form-col text-right">
                <button class="btn-primary" @click="uploadFile()">Next</button>
            </div>
        </div>
    </div>
</template>

<script>
import EventBus from "@/utils/EventBus";
import {mapActions, mapGetters} from "vuex";
import FileInput from "@/components/ui/FileInput";

export default {
    components: { FileInput },
    emits: ['parse-file-data'],
    data: () => {
        return {
            uploadedFile: null,
            website: null,
        }
    },
    methods: {
        ...mapActions({
            notifyError: 'alerts/notifyError',
        }),
        uploadFile() {
            if(this.validator.passes()) {
                let formData = new FormData();
                formData.append('file', this.uploadedFile);
                let params = {
                    websiteId: this.website.id,
                    data: formData
                };

                this.$cmsDataProvider.create('importFloorplansFile', params).then((response) => {
                    EventBus.emit('next-step');
                    this.$emit('parse-file-data', response);
                })
                .catch(() => {
                    this.notifyError('There was an error uploading or processing the file.');
                });
            }
        }      
    },
    computed: {
        ...mapGetters({
            getWebsiteInfo: 'website_info/getWebsiteInfo'
        }),
    },
    validations: {
        'uploadedFile': 'required'
    },
    created() {
        this.initValidator();
    },
    async mounted() {
        this.website = this.getWebsiteInfo
        this.validator.setCustomErrorMessages({
            'uploadedFile.required' : 'You must upload a file.'
        });
    },
}
</script>
