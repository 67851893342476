<template>
    <div>
        <div class="flex flex-col flex-1 h-full pb-8 mx-auto">
            <div class="flex items-center justify-center bg-blue-200 border border-blue-400 p-3 mb-4 text-sm text-blue-700">
                Select the matching columns from the uploaded file that are needed to process the import.
            </div>
            <div v-if="hasErrors" class="flex items-center justify-center bg-red-100 border border-red-400 p-3 mb-4 text-sm text-red-700">
                You must match all columns.
            </div>
            <div class="flex border-b-2 border-black ">
                <div class="w-2/3 px-4 py-2">
                    <p class="font-bold tracking-wider font-inter text-sm">floorplan column</p>
                </div>
                <div class="w-1/3 px-4 py-2">
                    <p class="font-bold tracking-wider font-inter text-sm">uploaded file column</p>
                </div>
            </div>
            <div class="flex items-center border-b border-gray-200 pb-4" :class="{ 'matched-row': selectedColumns.unit_type !== ''}">
                <div class="w-2/3  p-4">
                    <p class="text-sm tracking-widest font-frank">Unit Type</p>
                    <p class="text-xs font-inter tracking-wider text-gray-600 w-3/4">
                        Choose the column from the upload file that matches the Unit Type.
                    </p>
                </div>
                <div class="w-1/3 p-4 ">
                    <select class="outline-none text-sm" v-model="selectedColumns.unit_type">
                        <option value="">Choose Column...</option>
                        <option v-for="(column, idx) in fileColumns" :value="idx" :key="column">
                            {{ column }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="flex border-b border-gray-200 pb-4" :class="{ 'matched-row': selectedColumns.starting_price !== ''}">
                <div class="w-2/3  p-4">
                    <p class="text-sm tracking-widest font-frank">Starting Price</p>
                    <p class="text-xs font-inter tracking-wider text-gray-600 w-3/4">
                        Choose the column from the upload file that matches the Starting Price that will be displayed on the website.
                    </p>
                </div>
                <div class="w-1/3 p-4 ">
                    <select class="outline-none text-sm mt-1" v-model="selectedColumns.starting_price">
                        <option value="">Choose Column...</option>
                        <option v-for="(column, idx) in fileColumns" :value="idx" :key="column">
                            {{ column }}
                        </option>
                    </select>
                    <div class="border-t border-blue-500 mt-4" v-if="selectedColumns.starting_price !== ''">
                        <p class="text-xs font-semibold mt-4 pl-1 mb-1">Which value would you to use?</p>
                        <label class="flex items-center pl-1 text-xs mb-1">
                            <input class="mr-2" name="priceFactor" type="radio" value="min" v-model="pricingFactor" /> <span>Lowest Price</span>
                        </label>
                        <label class="flex items-center pl-1 text-xs mb-1">
                            <input class="mr-2" name="priceFactor" type="radio" value="avg" v-model="pricingFactor" /> <span>Average Price</span>
                        </label>
                        <label class="flex items-center pl-1 text-xs mb-1">
                            <input class="mr-2" name="priceFactor" type="radio" value="max" v-model="pricingFactor" /> <span>Higest Price</span>
                        </label>
                    </div>
                </div>
            </div>
            <div class="flex" :class="{ 'matched-row': selectedColumns.status !== ''}">
                <div class="w-2/3  p-4">
                    <p class="text-sm tracking-widest font-frank">Status</p>
                    <p class="text-xs font-inter tracking-wider text-gray-600 w-3/4">
                        Choose the column from the upload file that matches the Status that will represent whether the Unit should
                        be marked as available.
                    </p>
                </div>
                <div class="w-1/3 p-4 ">
                    <select class="outline-none text-sm mt-1" v-model="selectedColumns.status">
                        <option selected value="">Choose Column...</option>
                        <option v-for="(column, idx) in fileColumns" :value="idx" :key="column">
                            {{ column }}
                        </option>
                    </select>
                    <div class="border-t border-blue-500 mt-4" v-if="selectedColumns.status !== ''">
                        <p class="text-xs font-semibold mt-4 pl-1 mb-1">Which status(es) represent available?</p>
                        <label v-for="(value, idx) in statusColumnValues" :key="idx" class="flex items-center pl-1 text-xs mb-1">
                            <input class="mr-2" name="statusOptions" type="checkbox" :value="value" v-model="availableStatuses" /> <span>{{ value }}</span>
                        </label>
                    </div>
                </div>
            </div>
        </div>
        <div class="form-row modal-footer">
            <div class="form-col text-right">
                <button class="btn-primary btn-transparent mr-4" @click="previous()">Back</button>
                <button class="btn-primary" @click="process()">Next</button>
            </div>
        </div>
    </div>
</template>

<script>
import EventBus from "@/utils/EventBus";
import {mapActions, mapGetters} from "vuex";

export default {
    props: {
        fileData: {
            type: Object,
            required: true
        }
    },
    emits: ['set-import-results'],
    data: () => {
        return {
            selectedColumns: {
                unit_type: '',
                starting_price: '',
                status: ''
            },
            availableStatuses: [],
            pricingFactor: 'min',
            website: null,
            hasErrors: false,
        }
    },
    validations: {
        'selectedColumns.unit_type': 'required',
        'selectedColumns.starting_price': 'required',
        'selectedColumns.status': 'required',
    },
    methods: {
        ...mapActions({
            notifyError: 'alerts/notifyError',
        }),
        previous() {
            EventBus.emit('previous-step');
        },
        process() {
            this.hasErrors = false;
            if (this.validator.passes()) {
                let payload = {};
                payload.columns = this.selectedColumns;
                payload.statuses = this.availableStatuses;
                payload.pricingFactor = this.pricingFactor;
                payload.unitTypes = this.fileData.uniqueColumnValues[this.selectedColumns.unit_type];
                payload.rows = this.fileData.rows;

                let params = {
                    websiteId: this.website.id,
                    data: payload
                };

                this.$cmsDataProvider.create('importFloorplansProcess', params).then((response) => {
                    this.$emit('set-import-results', response);
                    EventBus.emit('next-step');
                })
                .catch(() => {
                    this.notifyError('There was an error uploading or processing the file.');
                });
            } else {
                this.hasErrors = true;
            }
        }       
    },
    created() {
        this.initValidator();
    },
    async mounted() {
        this.website = this.getWebsiteInfo
    },
    computed: {
        fileColumns() {
            return this.fileData.header;
        },
        statusColumnValues() {
            return this.fileData.uniqueColumnValues[this.selectedColumns.status];
        },
        ...mapGetters({
            getWebsiteInfo: 'website_info/getWebsiteInfo'
        }),
    },
    watch: {
        'fileData': {
            handler: function() {
                this.selectedColumns.unit_type = this.fileColumns.findIndex(column => column === this.fileData.matches.unitType);
                this.selectedColumns.starting_price = this.fileColumns.findIndex(column => column === this.fileData.matches.startingPrice);
                this.selectedColumns.status = this.fileColumns.findIndex(column => column === this.fileData.matches.status);
            },
            deep: true
        }
    }
}
</script>


<style scoped>
    .matched-row {
        @apply bg-blue-200;
    }

    .matched-row select {
        @apply bg-blue-200;
    }
</style>
