<template>
    <div>
        <div class="flex flex-col flex-1 h-full pb-8 mx-auto">
            <div class="flex items-center justify-center bg-blue-200 border border-blue-400 p-3 mb-4 text-sm text-blue-700">
                The records below were updated with the import.
            </div>
            <div class="flex border-b-2 border-black ">
                <div class="w-1/3 px-4 py-2">
                    <p class="font-bold tracking-wider font-inter text-sm">floorplan</p>
                </div>
                <div class="w-1/3 px-4 py-2">
                    <p class="font-bold tracking-wider font-inter text-sm">availalbe</p>
                </div>
                <div class="w-1/3 px-4 py-2">
                    <p class="font-bold tracking-wider font-inter text-sm">starting price</p>
                </div>
            </div>
            <div class="flex" v-for="floorplan in floorplanResults" :key="floorplan.unit_type">
                <div class="w-1/3 px-4 py-2">
                    <p class="font-bold tracking-wider font-frank text-sm">{{ floorplan.community_unit_types_name }}</p>
                </div>
                <div class="w-1/3 px-4 py-2">
                    <p class="font-bold tracking-wider font-frank  text-sm">{{ floorplan.available_units }}</p>
                </div>
                <div class="w-1/3 px-4 py-2">
                    <p class="font-bold tracking-wider font-frank text-sm">${{ floorplan.starting_price }}</p>
                </div>
            </div>
        </div>
        <div class="form-row modal-footer">
            <div class="form-col text-right">
                <modal-link route="websites.floorplans" :params="{ id: $route.params.id }">
                    <button class="btn-primary" @click="process()">View all floor plans</button>
                </modal-link>
            </div>
        </div>
    </div>
</template>

<script>
import ModalLink from "@/components/ui/modals/ModalLink";

export default {
    components: { ModalLink },
    props: {
        results: {
            required: true,
            type: Array,
        }
    },
    computed: {
        floorplanResults() {
            return this.results;
        }
    }
}
</script>